<template>
  <div class="content-table-mobile">
    <div v-if="bLoadingTable" class="content-progress-linear">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
      ></v-progress-linear>
    </div>
    <div v-if="!bLoadingTable">
      <div v-if="bEmptyTable" class="content-empty-table">
        <span class="text-empty-table"
          >No hay información disponible para mostrar.</span
        >
      </div>
      <v-divider class="divider-global mt-3 mb-3"></v-divider>

      <div id="TableMobileOC" v-for="(itemTable, index) in aTable" :key="index">
        <v-container>
          <div class="content-all-first">
            <div class="content-left-first">
              <span class="mr-2">
                {{ itemTable.dNumTransation }}
              </span>
              <div class="content-color-id-raw-material">
                <div
                  :style="{
                    'background-color':
                      '#' + itemTable.sColorKeyRawMaterial + '!important',
                  }"
                  class="color-raw-material"
                ></div>
              </div>
              {{ itemTable.sCode }}
            </div>
            <div class="content-right-first">
              <menu-accion-table-component-global
                @openImgsOP="openImgsOP"
                @openDeleteItem="openDeleteItem"
                @openWaste="openWaste"
                @openModify="openModify"
                :aMenuAccion="aMenuAccionFiltered"
                :itemTable="itemTable"
              />
            </div>
          </div>
          <div class="content-all">
            <div class="content-left">
              <p>Nombre</p>
            </div>
            <div class="content-right">
              <p class="text-right">{{ itemTable.sNameRawMaterial }}</p>
            </div>
          </div>
          <div class="content-all">
            <div class="content-left">
              <p>Peso</p>
            </div>
            <div class="content-right">
              <p class="text-right">
                {{ formatMoneyGlobal(itemTable.dWeightRawMaterial) }} Kg
                <span>
                  {{ itemTable.bModified ? "(modificado)" : "" }}
                </span>
              </p>
            </div>
          </div>
          <div class="content-all">
            <div class="content-left">
              <p>Merma</p>
            </div>
            <div class="content-right">
              <p class="text-right">
                {{ formatMoneyGlobal(itemTable.dUnprocessedWeight) }} Kg
              </p>
            </div>
          </div>
          <div class="content-all" v-if="bShowMoneyRawMaterial">
            <div class="content-left">
              <p>Precio unitario</p>
            </div>
            <div class="content-right">
              <p class="text-right">
                ${{ formatMoneyGlobal(itemTable.dUnitPriceRawMaterialMXN) }} MXN /
                ${{ formatMoneyGlobal(itemTable.dUnitPriceRawMaterialUSD) }} USD
              </p>
            </div>
          </div>
          <div class="content-all" v-if="bShowMoneyRawMaterial">
            <div class="content-left">
              <p>Subtotal</p>
            </div>
            <div class="content-right">
              <!-- <div class="content-chip-difference">
                <v-chip
                  class="chip-difference"
                  :color="getColor(itemTable.dSubtotal)"
                  dark
                >
                  <div class="content-text-icon-variation">
                    <div class="content-icon-variation">
                      <v-icon>{{ getIcon(itemTable.dVariation) }}</v-icon>
                    </div>
                    <div class="text-variation">{{ itemTable.dVariation }} %</div>
                  </div>
                </v-chip>
              </div> -->
              <p class="text-right">
                ${{ formatMoneyGlobal(itemTable.dSubtotalRawMaterialMXN) }} MXN / ${{ formatMoneyGlobal(itemTable.dSubtotalRawMaterialUSD) }} USD
              </p>
            </div>
          </div>
          <div class="content-all">
            <div class="content-left">
              <p>Nombre del responsable</p>
            </div>
            <div class="content-right">
              <p class="text-right">{{ itemTable.sManagerRawMaterial }}</p>
            </div>
          </div>
        </v-container>
        <v-divider class="divider-global mt-3 mb-3"></v-divider>
      </div>
    </div>
    <footer-table-component-global
      :oItem="oItem"
      @changeTable="getItem"
      @setDialogConfirmationGlobal="setDialogConfirmationGlobal"
      :bAdminPermissions="bAdminPermissions"
      :bBlockAddRawMaterial="bBlockAddRawMaterial"
      :numPages="numPages"
      :totalItems="totalItems"
    />

    <v-modify-waste
      @close="closeWeightDialog"
      @refresh="changeTable"
      :oPurchaseOrder="targetItem"
      :active="bModifyWaste"
    ></v-modify-waste>
    <v-modify-material
      @close="bModifyMaterial = false"
      @refresh="changeTable"
      :oPurchaseOrder="targetItem"
      :active="bModifyMaterial"
    ></v-modify-material>
  </div>
</template>

<script>
import ModifyWasteOP from "../../operations_detail_component_global/ModifyWasteOP.vue";
import ModifyMaterialOP from "../../operations_detail_component_global/ModifyMaterialOP.vue";

export default {
  props: {
    headers: Array,
    aTable: Array,
    numPages: Number,
    totalItems: Number,
    bChangeSelectedItem: Boolean,
    oItem: Object,
    bAdminPermissions: Boolean,
    bBlockAddRawMaterial: Boolean,
    numPages: Number,
    totalItems: Number,
  },
  data() {
    return {
      bLoadingTable: true,
      bEmptyTable: false,
      bModifyMaterial: false,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      totalView: 0,
      aMenuAccion: [
        {
          text: "Imágenes",
          icon: "mdi-image-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 4,
        },
        {
          text: "Merma",
          icon: "mdi-bucket-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 5,
        },
        {
          text: "Modificar",
          icon: "mdi-pencil",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 6,
        },
        // {
        //   text: "Eliminar",
        //   icon: "mdi-trash-can-outline",
        //   toGo: "ProviderDetail",
        //   color: "var(--primary-color-btn-table-delete) !important",
        //   action: 2,
        // },
      ],
      aMenuAccionAlt: [
        {
          text: "Imágenes",
          icon: "mdi-image-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 4,
        },
        {
          text: "Eliminar",
          icon: "mdi-trash-can-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-btn-table-delete) !important",
          action: 2,
        },
      ],
      aMenuAccionImages: [
        {
          text: "Imágenes",
          icon: "mdi-image-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 4,
        },
      ],
      aMenuAccionAddRawMaterial: [
        {
          text: "Eliminar",
          icon: "mdi-trash-can-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-btn-table-delete) !important",
          action: 2,
        },
      ],
      aMenuAccionImagesMerma: [
        {
          text: "Imágenes",
          icon: "mdi-image-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 4,
        },
        {
          text: "Merma",
          icon: "mdi-bucket-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 5,
        },
      ],
      widthTable: 0,
      aShowMoneyRawMaterial: [
        "OPGeneralDetail",
        "OPDetailReport",
        "OPPayableDetail",
      ],
      bModifyWaste: false,
      targetItem: null,
      sIdOrderStatus: "",
      bIdOrderStatusFinaly: false,
      aShowEditMerma: [
        "OPGeneralDetail",
        "OPDetailReport",
        "OPPendingDetail",
        "OPTransationDetailAccount",
        "OPDetailTransactionDetail",
      ],
      aShowEditMaterial: [
        "OPGeneralDetail",
        "OPDetailReport",
        "OPTransationDetailAccount",
        "OPDetailTransactionDetail",
      ],
    };
  },
  components: {
    VModifyWaste: ModifyWasteOP,
    VModifyMaterial: ModifyMaterialOP,
  },
  beforeMount() {
    this.setLoadingTable();
  },
  updated() {
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    closeWeightDialog() {
      this.bModifyWaste = false;
      this.targetItem = null;
    },
    openWaste(item) {
      this.targetItem = item;
      this.bModifyWaste = true;
    },
    openModify(item) {
      this.targetItem = item;
      this.bModifyMaterial = true;
    },
    handleResize() {
      this.$nextTick((e) => {});
    },
    setOrderIdStatus() {
      this.sIdOrderStatus = this.oItem.oOrderStatus.sStatusId;
    },

    //#region FUNCTIONS GLOBALS //
    setLoadingTable() {
      this.bLoadingTable = true;
      this.bEmptyTable = false;
      setTimeout(() => {
        this.bLoadingTable = false;
        this.bEmptyTable = this.aTable.length < 1;
      }, 1000);
    },
    getItem(obj) {
      this.$emit("changeTable", obj);
    },
    openDetailItem(item) {
      this.$emit("openDetailItem", item);
    },
    openDeleteItem(item) {
      this.$emit("openDeleteItem", item);
    },
    openImgsOP(item) {
      this.$emit("setDialogImgsOP", item, false);
    },
    changeTable(obj) {
      this.$emit("changeTable", obj);
    },
    setDialogConfirmationGlobal(oItem) {
      this.$emit("setItemDialogConfirmationGlobal", oItem);
      // this.bDialogConfirmationGlobal = !this.bDialogConfirmationGlobal;
    },

    //#endregion   FUNCTIONS GLOBALS //

    //#region FUNCTIONS RAW MATERIALS //
    getColor(difference) {
      if (difference > 0) return "#00A85B";
      else if (difference < 0) return "#E85353";
      else return "#FFD54F";
    },
    getIcon(difference) {
      if (difference > 0) return "mdi-arrow-up";
      else if (difference < 0) return "mdi-arrow-up";
      else return "mdi-arrow-left-right";
    },
    //#endregion FUNCTIONS RAW MATERIALS //
  },
  computed: {
    bWasteActive() {
      return this.$route.name == "OPGeneralDetail";
    },
    bShowEditMerma() {
      return (
        this.aShowEditMerma.filter((e) => e === this.$route.name).length > 0
      );
    },
    bShowEditMaterial() {
      return (
        this.aShowEditMaterial.filter((e) => e === this.$route.name).length > 0
      );
    },
    bAddRawMaterial() {
      return this.$route.name === "AddOperation";
    },
    aMenuAccionFiltered() {
      if (this.bShowEditMerma && this.bShowEditMaterial) {
        return this.aMenuAccion;
      } else if (this.bShowEditMerma && !this.bShowEditMaterial) {
        return this.aMenuAccionImagesMerma;
      } else if (this.bAddRawMaterial) {
        return this.aMenuAccionAddRawMaterial;
      } else {
        return this.aMenuAccionImages;
      }
    },
    bShowMoneyRawMaterial() {
      return (
        this.aShowMoneyRawMaterial.filter((e) => e === this.$route.name)
          .length > 0
      );
    },
  },
  watch: {
    aTable() {
      this.setLoadingTable();
    },
    oItem() {
      this.setOrderIdStatus();
    },
  },
};
</script>

<style>
/*#region CSS GLOBAL  */
.content-all-first {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.content-all {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-left {
  width: 50%;
  font-family: "pop-Semibold";
  font-size: 14px;
}

.text-right {
  /* white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden; */
}

.content-right-first {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
  position: relative !important;
}

.content-right {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
}

.content-right {
  overflow: auto;
}

.content-right::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
  border-radius: 10px;
}

.content-title {
  text-align: start;
}

.text-head {
  color: var(--primary-color-text) !important;
}

.content-text {
  /* text-align: end; */
}

.text-response {
  color: var(--primary-color-text) !important;
}

.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.content-empty-table {
  padding: 10px 15px 0px 15px;
  text-align: center;
}

.text-empty-table {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
}

/*#endregion CSS GLOBAL  */

/*#region CSS RAW MATERIAL */

/*#region CSS SKEYCOLOR */
.content-color-id-raw-material {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.color-raw-material {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid var(--primary-color-text);
}

/*#endregion CSS SKEYCOLOR */

/*#region CSS VARIATION */
.content-chip-difference {
  display: flex;
  justify-content: end;
  width: 100%;
}

.chip-difference {
  align-content: center;
  width: 120px;
  text-align: center !important;
  justify-content: center;
  display: flex;
  font-family: "pop-Bold";
}

.content-text-icon-variation {
  display: flex;
  width: 120px;
}

.content-icon-variation {
  width: auto !important;
}

.text-variation {
  width: 100% !important;
  text-align: end !important;
  align-self: center !important;
}

/*#endregion CSS VARIATION */

/*#endregion CSS RAW MATERIAL */
</style>
